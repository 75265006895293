import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import Image from 'gatsby-image'
import React, { useContext, useState } from 'react'

import GallerySection from '../../components/CarryAll/GallerySection'
import DropdownSelect from '../../components/DropdownSelect'
import DetailsSection from '../../components/Landing/DetailsSection'
import ExploreSection from '../../components/Landing/ExploreSection'
import Layout from '../../components/Layout'
import SelectorSection from '../../components/SelectorSection'
import SEO from '../../components/seo'
import StoreContext from '../../context/StoreContext'
import fbq from '../../helper/fpq'
import useLocalisation from '../../helper/useLocalisation'

const LandingContainer = styled.div`
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  @media (min-width: 763px) {
    flex-direction: row;
    padding: 64px 96px;
    padding-top: 64px;
    margin-top: 114px;
  }
`

const ImageConatainer = styled.div`
  display: flex;
  align-items: stretch;
  @media (max-width: 762px) {
    flex-direction: column;
  }
`
const ImageBanner = styled(Image)`
  width: 100%;
  @media (min-width: 763px) {
    width: 50%;
  }
`

const variants = {
  'july-us': [
    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjkyNDAwODU0NjQwNw==',
    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjkyNDAwODYxMTk0Mw==',
    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjkyNDAwODY0NDcxMQ==',
  ],
  'july-travel': [
    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjkwNDQ0NzU1NzY4OQ==',
    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjkwNDQ0NzU5MDQ1Nw==',
    'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8zMjkwNDQ0NzYyMzIyNQ==',
  ],
}

const GiftCard = ({ data, pageContext }) => {
  let [selectedValue, setSelectedValue] = useState(0)
  const context = useContext(StoreContext)
  const { currency } = context
  const Q = useLocalisation()
  const cardSubs = [
    {
      title: '$50',
      value: 50,
      sub: Q('gift-card-85'),
      id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xOTQ5MzYyMzM2NTcwMQ==',
      fb: 19493623365701,
    },
    {
      title: '$100',
      value: 100,
      sub: Q('gift-card-295'),
      id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8xOTQ5MzYyMzEwMzU1Nw==',
      fb: 19493623103557,
    },
    {
      title: '$200',
      value: 200,
      sub: Q('gift-card-395'),
      id: 'Z2lkOi8vc2hvcGlmeS9Qcm9kdWN0VmFyaWFudC8yOTE4OTAwMjEzMzU3Mw==',
      fb: 29189002133573,
    },
  ]

  const cardList = cardSubs.map((value, key) => ({
    id: key + 1,
    name: value.title,
  }))

  const handleAddToCart = async (e) => {
    // if (context.currency !== 'au') return
    e.preventDefault()
    await context.addVariantToCart(variants[context.currency][selectedValue], 1)
    fbq(context.currency, 'AddToCart', {
      content_name: 'Gift Card',
      content_category: 'Gift Card',
      content_ids: [cardSubs[selectedValue].fb],
      content_type: 'product',
      value: cardSubs[selectedValue].value,
      currency:
        context.language
          .replace('root', 'au')
          .replace('global', 'us')
          .toUpperCase() + 'D',
    })
    context.openCart()
  }
  let total = cardSubs[selectedValue].title

  return (
    <>
      <SEO title={'Gift Card'} description={''} />
      <Layout location="product">
        <LandingContainer>
          <GallerySection
            selectedColor={0}
            images={[data.Gift]}
            videos={[]}
            giftCard={true}
          />
          <SelectorSection
            noWarrenty={true}
            noPersonalisation={true}
            handleBefore={() => {}}
            handleAddToCart={handleAddToCart}
            selectedColor={0}
            setSelectedColor={() => {}}
            name="Gift Card"
            price={cardSubs[selectedValue].title}
            lifetime={true}
            noSet={true}
            aboveAddTocart={
              <>
                <DropdownSelect
                  selected={selectedValue}
                  optionsList={cardList}
                  setValue={setSelectedValue}
                />
              </>
            }
            outline={
              <>
                Gift cards are delivered by email and contain instructions to
                redeem at checkout. The recipient has the option to choose their
                favourite July colour and personalise their shipping address.
                <br /> <br />
                Select from the following options or create your own tailored
                July Gift Card.
              </>
            }
          />
        </LandingContainer>
        <DetailsSection color="#F6F3EF" />
        <ImageConatainer>
          <ImageBanner fluid={data.Ban2.childImageSharp.fluid} />
          <ImageBanner fluid={data.Ban1.childImageSharp.fluid} />
        </ImageConatainer>
        <ExploreSection />
      </Layout>
    </>
  )
}
export const query = graphql`
  query {
    GiftDetails: cockpitProducts(handle: { value: { eq: "gift-card" } }) {
      handle {
        value
      }
      title {
        value
      }
      description {
        value
      }
      specs {
        value
      }
    }
    Gift: file(relativePath: { eq: "gift-card/gift.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ban1: file(relativePath: { eq: "gift-card/ban1.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    Ban2: file(relativePath: { eq: "gift-card/ban2.png" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 2000) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
    GiftCard: file(relativePath: { eq: "shop-page/giftcard.png" }) {
      childImageSharp {
        fluid(maxWidth: 500) {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
  }
`

export default GiftCard
